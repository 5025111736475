import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import AddDrinkForm from './AddDrinkForm';
import { addDrinkActionCreator, IDrink, removeDrinkActionCreator, VolumeUnit } from '../../store/drinkList';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { Guid } from 'guid-typescript';
import CalculatedPercentage from './CalculatedPercentage';
import { getCurrentTimeEpochMilliseconds } from '../../epochConverter';

export const scanButtonSize: number = 9;
export const barPadding: number = 3;

const styles = (theme: Theme) => createStyles({
    root: {
    },
});

interface IProps extends WithStyles<typeof styles> {
    drinkList: IDrink[];
    addDrink: (drink: IDrink) => void;
    removeDrink: (drinkId: string) => void;
}

const DrinkList: React.FC<IProps> = ({
    drinkList,
    addDrink,
    removeDrink,
    classes,
}) => {
    const removeDrinkCallback = React.useCallback((drinkId: string) => {
        removeDrink(drinkId);
    }, [removeDrink]);

    const addAnotherDrinkCallback = React.useCallback((abv: number, volume: number, unit: VolumeUnit) => {
        addDrink({
            id: Guid.create().toString(),
            timeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
            abvPercent: abv,
            amt: volume,
            unit: unit,
        } as IDrink);
    }, [addDrink]);

    return <>
        <CalculatedPercentage />
        <Table aria-label="order table">
            <TableHead>
                <TableRow>
                    <TableCell>Percentage</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Copy</TableCell>
                    <TableCell>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {drinkList.sort((a, b) => b.timeEpochMilliseconds - a.timeEpochMilliseconds).map(d =>
                    <TableRow key={d.id}>
                        <TableCell>{d.abvPercent}</TableCell>
                        <TableCell>{d.amt}{d.unit}</TableCell>
                        <TableCell><Button onClick={() => addAnotherDrinkCallback(d.abvPercent, d.amt, d.unit)}>+</Button></TableCell>
                        <TableCell><Button onClick={() => removeDrinkCallback(d.id)}>&times;</Button></TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
        <fieldset>
            <legend>Add Drink</legend>
            <AddDrinkForm />
        </fieldset>
    </>;
}

const mapStateToProps = (store: IAppState) => {
    return {
        drinkList: store.drinkList.drinks,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addDrink: (drink: IDrink) => dispatch(addDrinkActionCreator(drink)),
        removeDrink: (drinkId: string) => dispatch(removeDrinkActionCreator(drinkId)),
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(DrinkList));