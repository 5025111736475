import { Reducer, Action, AnyAction, ActionCreator } from 'redux';
import { neverReached, IAppState } from '.';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

// Store
export type VolumeUnit = "ml" | "oz";

export interface IDrink {
    id: string;
    abvPercent: number;
    amt: number;
    unit: VolumeUnit;
    timeEpochMilliseconds: number;
}

export interface IDrinkListState {
    drinks: IDrink[];
}

const initialSyncState: IDrinkListState = {
    drinks: [],
};

// Actions
interface IAddDrinkAction extends Action<'AddDrinkAction'> {
    toAdd: IDrink;
}

interface IRemoveDrinkAction extends Action<'RemoveDrinkAction'> {
    toRemoveId: string;
}

interface IEmptyListAction extends Action<'EmptyListAction'> {}

export type DrinkListActions = 
    | IAddDrinkAction
    | IRemoveDrinkAction
    | IEmptyListAction;

// Action Creators
export const addDrinkActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,      // The type for the data within the last action
        null,           // The type of the parameter for the nested function 
        IAddDrinkAction // The type of the last action to be dispatched
    >
> = (toAdd: IDrink) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        dispatch({
            type: 'AddDrinkAction',
            toAdd,
        } as IAddDrinkAction);
    };
};

export const removeDrinkActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,      // The type for the data within the last action
        null,           // The type of the parameter for the nested function 
        IAddDrinkAction // The type of the last action to be dispatched
    >
> = (drinkId: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        dispatch({
            type: 'RemoveDrinkAction',
            toRemoveId: drinkId,
        } as IRemoveDrinkAction);
    };
};

export const emptyDrinksActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,      // The type for the data within the last action
        null,           // The type of the parameter for the nested function 
        IAddDrinkAction // The type of the last action to be dispatched
    >
> = () => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        dispatch({
            type: 'EmptyListAction',
        } as IEmptyListAction);
    };
};

// Reducers
export const drinkListReducer: Reducer<IDrinkListState, DrinkListActions> = (
    state = initialSyncState,
    action,
) => {
    switch (action.type) {
        case 'AddDrinkAction': {
            return {
                ...state,
                drinks: [ ...state.drinks, action.toAdd ],
            };
        }
        case 'RemoveDrinkAction': {
            return {
                ...state,
                drinks: [ ...state.drinks.filter(d => d.id !== action.toRemoveId) ],
            };
        }
        case 'EmptyListAction': {
            return {
                ...state,
                drinks: [],
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};