import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Button, Theme, createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { renderSelectField, renderTextField } from '../muiReduxFormIntegration';
import { CircularProgressWithHidden } from '../UserInterface/AddHidden';
import { IAppState } from '../../store';
import { addDrinkActionCreator, IDrink, VolumeUnit } from '../../store/drinkList';
import { Guid } from 'guid-typescript';
import { getCurrentTimeEpochMilliseconds } from '../../epochConverter';

export const AddDrinkFormName: string = 'addDrinkForm';

const styles = (theme: Theme) => createStyles({
    root: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
});

interface IFormProps extends WithStyles<typeof styles> {
    loading: boolean;
}

export interface IAddDrinkForm {
    abvPercent: number;
    volume: number;
    unit: VolumeUnit;
}

const FormComponent: React.FC<InjectedFormProps<IAddDrinkForm, IFormProps> & IFormProps> = ({
    classes,
    handleSubmit,
    pristine,
    submitting,
    loading,
}) => {
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Field
                name="abvPercent"
                component={renderTextField}
                type="number"
                label="ABV Percentage"
                disabled={submitting || loading}
                margin="normal"
                parse={(val: any) => parseInt(val, 10)}
                required
            />
            <Field
                name="volume"
                component={renderTextField}
                type="number"
                label="Volume"
                disabled={submitting || loading}
                margin="volume"
                parse={(val: any) => parseInt(val, 10)}
                required
            />
            <Field
                name="unit"
                component={renderSelectField}
                label="Unit"
                required
            >
                <option value="oz">oz</option>)
                <option value="ml">mL</option>)
            </Field>
            <Grid container direction="row" alignItems="center" justify="center">
                <Button
                    size="small"
                    variant="contained"
                    color="primary" 
                    aria-label="Send Request" 
                    type="submit"
                    disabled={pristine || submitting || loading}>
                    Add Drink
                </Button>
                <CircularProgressWithHidden hidden={!submitting && !loading} />
            </Grid>
        </form>);
};

const ConnectedFormComponent = withStyles(styles)(reduxForm<IAddDrinkForm, IFormProps>({
    form: AddDrinkFormName,
    onSubmitSuccess: (result, dispatch, props) => {
        if (props.reset !== undefined) {
            props.reset();
        }
        //document.getElementsByName('newItemValue')[0].focus();
    }
})(FormComponent));

interface IProps {
    addDrink: (drink: IDrink) => void;
}

const AddDrinkForm: React.FC<IProps> = ({
    addDrink,
}) => {
    const submitCallback = React.useCallback(async (values: IAddDrinkForm) => {
        addDrink({
            id: Guid.create().toString(),
            timeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
            abvPercent: values.abvPercent,
            amt: values.volume,
            unit: values.unit,
        } as IDrink);
    }, [addDrink]);

    return (
        <ConnectedFormComponent
            loading={false}
            enableReinitialize={true}
            onSubmit={submitCallback}
            initialValues={{ unit: "oz" }}
        />
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        addDrink: (drink: IDrink) => dispatch(addDrinkActionCreator(drink))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddDrinkForm);