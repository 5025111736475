import { Reducer, Action, AnyAction, ActionCreator } from 'redux';
import { neverReached, IAppState } from '.';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

// Store
export type Gender = "Male" | "Female";

export interface IUserSettingsState {
    readonly age: number;
    readonly gender: Gender;
    readonly weightLbs: number;
}

const initialSyncState: IUserSettingsState = {
    age: 18,
    gender: "Male",
    weightLbs: 160,
};

// Actions
interface IUpdateUserSettings extends Action<'UpdateUserSettings'> {
    age: number;
    gender: Gender;
    weightLbs: number;
}

interface IEmptyAction extends Action<'EmptyAction'> {}

export type UserSettingsActions = 
    | IUpdateUserSettings
    | IEmptyAction;

// Action Creators
export const updateUserSettingsActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,   // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,          // The type for the data within the last action
        null,               // The type of the parameter for the nested function 
        IUpdateUserSettings // The type of the last action to be dispatched
    >
> = (age: number, gender: Gender, weightLbs: number) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        dispatch({
            type: 'UpdateUserSettings',
            age,
            gender,
            weightLbs,
        } as IUpdateUserSettings);
    };
};

// Reducers
export const userSettingsReducer: Reducer<IUserSettingsState, UserSettingsActions> = (
    state = initialSyncState,
    action,
) => {
    switch (action.type) {
        case 'UpdateUserSettings': {
            return {
                ...state,
                age: action.age,
                weightLbs: action.weightLbs,
                gender: action.gender,
            };
        }
        case 'EmptyAction': {
            return state;
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};