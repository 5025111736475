import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { IDrink } from '../../store/drinkList';
import { Typography } from '@material-ui/core';
import { Gender } from '../../store/userSettings';
import { getCurrentTimeEpochMilliseconds } from '../../epochConverter';

const styles = (theme: Theme) => createStyles({
    root: {
    },
});

interface IProps extends WithStyles<typeof styles> {
    drinkList: IDrink[];
    weightLbs: number;
    gender: Gender;
}

const CalculatedPercentage: React.FC<IProps> = ({
    drinkList,
    weightLbs,
    gender,
    classes,
}) => {
    const alcoholGrams = React.useMemo(() => 
        drinkList.reduce((prev, current) => prev + 
            (current.amt * current.abvPercent * (current.unit === "oz" ? 0.233334915 : 0.00789))
        , 0), [drinkList]);
    const gsr = React.useMemo(() => 
        (gender === "Male" ? 0.68 : 0.55) * (weightLbs / 0.0022046),
        [weightLbs, gender]);
    const firstDrinkTimeMilliseconds = React.useMemo(() => drinkList.reduce((prev, curr) =>
        Math.min(prev, curr.timeEpochMilliseconds), getCurrentTimeEpochMilliseconds()),
        [drinkList]);
    const millisecondsSinceFirstDrink = React.useMemo(() => getCurrentTimeEpochMilliseconds() - firstDrinkTimeMilliseconds, [firstDrinkTimeMilliseconds]);
    const hoursSinceFirstDrinkSubtractor = millisecondsSinceFirstDrink * 0.000000004166666667;
    const calculatedPercentage = ((alcoholGrams / gsr) * 100) - hoursSinceFirstDrinkSubtractor; 

    return <>
        <Typography variant="h3">{drinkList.length === 0 ? 0 : (Math.round(calculatedPercentage * 10000) / 10000)}%</Typography>
    </>;
}

const mapStateToProps = (store: IAppState) => {
    return {
        drinkList: store.drinkList.drinks,
        weightLbs: store.userSettings.weightLbs,
        gender: store.userSettings.gender,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CalculatedPercentage));
