import React from 'react';
import { Route } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles } from '@material-ui/core';
import { history } from '../store';
import { ConnectedRouter } from 'connected-react-router';
import BottomBar from './BottomBar';
import CalculatorPage from './CalculatorPage';

// min height of 48 to work with AppBar
export const headerHeight: string = '48px';
export const walletBarHeight: string = '105px';

export class RootUrls {
    public static readonly calculator = () => '/';
}

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        maxWidth: 600,
        margin: '0 auto',
    },
    main: {
        minHeight: '100%',
    },
});

interface IProps extends WithStyles<typeof styles> {}

const AppRoute: React.FC<IProps> = ({
    classes,
}) => {
    return (
        <div className={classes.root}>
            <ConnectedRouter history={history}>
                <main className={classes.main}>
                    <Route exact path={RootUrls.calculator()} component={CalculatorPage} />
                </main>
                <BottomBar />
            </ConnectedRouter>
        </div>
    );
}

export default withStyles(styles)(AppRoute);