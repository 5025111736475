import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Button, Theme, createStyles, WithStyles, withStyles, Grid, FormControlLabel, Radio } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { renderRadioGroup, renderTextField } from './muiReduxFormIntegration';
import { CircularProgressWithHidden } from './UserInterface/AddHidden';
import { IAppState } from '../store';
import { Gender, updateUserSettingsActionCreator } from '../store/userSettings';

export const UserSettingsFormName: string = 'userSettingsForm';

const styles = (theme: Theme) => createStyles({
    root: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    creditCardContainer: {
        padding: theme.spacing(1),
        margin: `${theme.spacing(1)}px 0`,
    },
    tac: {
        '& .MuiFormControlLabel-label': theme.typography.subtitle2,
    },
    tacLinks: {
        "& a p": {
            display: 'inline-block',
        }
    },
    pickupChoices: {
        marginTop: theme.spacing(2),
        '& legend': {
            marginLeft: 0,
        },
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
        },
    },
});

interface IFormProps extends WithStyles<typeof styles> {
    loading: boolean;
}

export interface ISendRequestForm {
    age: number;
    weightLbs: number;
    gender: Gender;
}

const FormComponent: React.FC<InjectedFormProps<ISendRequestForm, IFormProps> & IFormProps> = ({
    classes,
    handleSubmit,
    pristine,
    submitting,
    loading,
}) => {
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Field
                name="age"
                component={renderTextField}
                type="number"
                label="Age"
                disabled={submitting || loading}
                margin="normal"
                parse={(val: any) => parseInt(val, 10)}
            />
            <Field
                name="weightLbs"
                component={renderTextField}
                type="number"
                label="Weight in Lbs"
                disabled={submitting || loading}
                margin="normal"
                parse={(val: any) => parseInt(val, 10)}
            />
            <Field
                name="gender"
                label="Gender"
                component={renderRadioGroup}
                className={classes.pickupChoices}
                parse={(val: any) => (val === "Male" ? "Male" : "Female") as Gender}
            >
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
            </Field>
            <Grid container direction="row" alignItems="center" justify="center">
                <Button
                    size="small"
                    variant="contained"
                    color="primary" 
                    aria-label="Send Request" 
                    type="submit"
                    disabled={pristine || submitting || loading}>
                    Save Settings
                </Button>
                <CircularProgressWithHidden hidden={!submitting && !loading} />
            </Grid>
        </form>);
};

const ConnectedFormComponent = withStyles(styles)(reduxForm<ISendRequestForm, IFormProps>({
    form: UserSettingsFormName,
})(FormComponent));

interface IProps {
    age: number;
    weightLbs: number;
    gender: Gender;
    updateUserSettings: (age: number, weightLbs: number, gender: Gender) => void;
}

const UserSettingsForm: React.FC<IProps> = ({
    age,
    weightLbs,
    gender,
    updateUserSettings,
}) => {
    const submitCallback = React.useCallback(async (values: ISendRequestForm) => {
        updateUserSettings(values.age, values.weightLbs, values.gender);
    }, [updateUserSettings]);

    return (
        <ConnectedFormComponent
            loading={false}
            enableReinitialize={true}
            initialValues={{
                age,
                weightLbs,
                gender,
            }}
            onSubmit={submitCallback}
        />
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        age: store.userSettings.age,
        weightLbs: store.userSettings.weightLbs,
        gender: store.userSettings.gender,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateUserSettings: (age: number, weightLbs: number, gender: Gender) => dispatch(updateUserSettingsActionCreator(age, gender, weightLbs))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserSettingsForm);