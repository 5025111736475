import React from 'react';
import DrinkList from '../components/DrinkList';
import UserSettingsForm from '../components/UserSettingsForm';

export default function CalculatorPage() {
    return (
        <>
            <DrinkList />
            <fieldset>
                <legend>Your Personal Info</legend>
                <UserSettingsForm />
            </fieldset>
        </>
    );
}