import React from 'react';
import { AnyAction } from 'redux';
import RehydrateWrapper from './components/Wrappers/RehydrateWrapper';
import Route from './route';
import { ThunkDispatch } from 'redux-thunk';
import ServiceWorkerUpdateWrapper from './components/Wrappers/ServiceWorkerUpdateWrapper';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from './theme';
import { CssBaseline } from '@material-ui/core';
import { IAppState } from './store';
import { connect } from 'react-redux';

interface IProps {
}

const ThemeWrapper: React.FC<IProps> = () => {
    return (
        <MuiThemeProvider theme={darkTheme}>
            <CssBaseline />
            <RehydrateWrapper>
                {/* <OfflineWrapper> */}
                    <ServiceWorkerUpdateWrapper>
                        <Route />
                    </ServiceWorkerUpdateWrapper>
                {/* </OfflineWrapper> */}
            </RehydrateWrapper>
        </MuiThemeProvider>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThemeWrapper);
